import { Badge, Collapse, Fade, IconButton } from "@mui/material";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import MenuLateral from "../../../componentes/gerais/Menu";
import TopoMenu from "../../../componentes/gerais/TopoMenu";
import { ContextoGeral } from "../../../contextos/ContextoGeral";
import { configAnim } from "../../../servicos/config/configAnim";
import { MdClose, MdSend } from "react-icons/md";
import { ChatContext } from "../../../contextos/ContextoChat";

import { print } from "../../../servicos/Uteis";
import { APISEM } from "../../../servicos/APISEM";
import ServicoAPI from "../../../servicos/ServicoAPI";
import { ChatServicoCorpo, ChatServicos, dateFormat } from "./ChatServicos";
import { BlocoEnviar } from "./Comp";

export default function Chat() {
	const { dados } = useContext(ContextoGeral)
	const { chat, dispatch, status } = useContext(ChatContext)

	const [central, setCentral] = useState(dados?.chatConfig?.entregadorPodeIniciarChat == "S")
	const [menu, setMenu] = useState(false)

	function counter() {
		let count = 0
		if (chat?.salas?.servicos) Object.keys(chat?.salas?.servicos).forEach(key => {
			if (chat?.salas?.servicos[key]?.unread)
				count += chat?.salas?.servicos[key]?.unread
		})
		return count
	}

	useEffect(() => {
		if (central && chat?.salas?.central?.idSala)
			dispatch({ type: "clearCentral" })
	}, [central])

	return <div>
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			<div style={{ padding: "1rem" }}>
				<TopoMenu voltar="/app/home" titulo="CHAT" setShow={setMenu} />
				{status == 0
					? <Spinner />
					: <Tabs
						variant="pills"
						justify
						onSelect={k => setCentral(k === "central")}
					>
						{dados?.chatConfig?.entregadorPodeIniciarChat == "S" && <Tab title={<>
							<Badge badgeContent={central ? 0 : chat?.salas?.central?.unread ?? 0} color="error">
								Central
							</Badge>
						</>} eventKey="central" onChange={() => setCentral(true)} />}
						{dados?.chatConfig?.clientePodeIniciarChat == "S" && <Tab title={<>
							<Badge badgeContent={!central ? 0 : counter()} color="error">
								Serviços
							</Badge>
						</>} eventKey="servicos" onChange={() => setCentral(false)} />}
					</Tabs>}
				<div style={{ position: "relative", height: "calc(100vh - 8rem)" }}>
					<div style={{
						height: "100%",
						position: "absolute",
						top: "0",
						left: central ? "0%" : "-120%",
						width: "100%",
						marginTop: "1rem",
						overflow: "auto",
						transition: "left 0.5s"
					}}>
						{dados?.chatConfig?.entregadorPodeIniciarChat == "S" && central && <ChatCentral />}
					</div>
					<div style={{
						height: "100%",
						position: "absolute",
						top: "0",
						left: central ? "120%" : "0%",
						width: "100%",
						marginTop: "1rem",
						transition: "left 0.5s",
					}}>
						{dados?.chatConfig?.clientePodeIniciarChat == "S" && !central && <ChatServicos />}
					</div>
					{dados?.chatConfig?.entregadorPodeIniciarChat != "S" && dados?.chatConfig?.clientePodeIniciarChat != "S" && <div>
						<div className="alert alert-danger mt-3 text-center">
							Chat indisponível, contate seu administrador.
						</div>
					</div>}
				</div>
			</div>
		</motion.div>
		<MenuLateral show={menu} setShow={setMenu} ativo="0" />
	</div>
}


function ChatCentral() {
	const { dados } = useContext(ContextoGeral)
	const { socket, chat, dispatch, connectCentral } = useContext(ChatContext)

	const [msg, setMsg] = useState("")
	const [loading, setLoading] = useState(true)
	const [init, setInit] = useState(false)
	const [API, setAPI] = useState(null)

	function enviarMensagem() {
		if (msg == "") {
			print("mensagem vazia")
			return
		}

		if (!chat?.salas?.central?.idSala) {
			print("sala não conectada")
			return
		}

		if (socket) {
			socket.emit("sendMessageToRoom", {
				roomName: `${dados.idConfig}_P${dados.idProf}`,
				salaId: chat?.salas?.central?.idSala,
				message: msg,
				nome: dados.nome
			})

			setMsg("")
		} else console.error("socket não conectado")
	}

	async function buscarMsgsAntigas() {
		if (chat) {
			const central = chat?.salas?.central
			if (central?.idSala) {
				const api = new APISEM(dados.dominio)
				// setAPI(api)
				await api.buscarMsgSala({
					idSala: central.idSala,
					limite: 10,
					idBusca: central?.previousID ?? null
				}).then(r => {
					if (r.status == "200") {
						const data = r.dados.dados
						if (data[data.length - 1]?.id)
							dispatch({
								type: "prevCentral", msgs: data.map(item => {									
									return {
										id: item.id,
										txt: item.mensagem,
										name: item.nome,
										outro: item.idUsuario != dados.idProf,
										enviado: true,
										recebido: true,
										date: new Date(item.timeCadastro * 1000)
									}
								}), previousID: data[data.length - 1].id
							})
					}
				}).finally(() => setLoading(false))
				dispatch({ type: "clearCentral" })
			}
		}
	}

	useEffect(() => {
		if (chat?.salas?.central?.unread > 0)
			dispatch({ type: "clearCentral" })
	}, [chat])

	useEffect(() => {
		if (loading) {
			if (!chat?.salas?.central?.idSala) connectCentral()

			const central = chat?.salas?.central
			if (central?.idSala && !init) {
				const api = new APISEM(dados.dominio)
				setAPI(api)
				if (central?.mensagens.length < 10) {
					buscarMsgsAntigas()
					setInit(true)
				}
				else setLoading(false)
				dispatch({ type: "clearCentral" })
			}
		}
	}, [loading, chat])

	return <div style={{
		display: "flex",
		flexDirection: "column",
		height: "100%"
	}}>
		<div style={{
			height: "100%",
			overflowY: "auto",
			display: "flex",
			flexDirection: "column-reverse"
		}}>
			<Fade in={loading}>
				<div style={{
					background: "white",
					height: "100%",
					width: "100%",
					position: "absolute",
					top: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}>
					<Spinner />
				</div>
			</Fade>
			{chat?.salas?.central?.mensagens.map((m, i) => <div
				key={i}
				style={{
					background: "white",
					padding: "0.5rem",
					borderRadius: "0.5rem",
					width: "80%",
					background: m.outro ? "var(--primaria-brighter)" : "var(--primaria)",
					color: "white",
					lineHeight: 1.1,
					marginTop: "3px",
					marginBottom: "3px",
					marginRight: "0.5rem",
					marginLeft: m.outro ? "0.5rem" : "auto",
					boxShadow: "0 0 15px -7px rgba(0,0,0,1)"
				}}
			>
				<div style={{
					lineHeight: 1,
					fontSize: "12px",
					fontWeight: 700,
					display: "flex",
					justifyContent: "space-between"
				}}>
					<div>{m.name}</div>
					<div>{dateFormat(m.date)}</div>
				</div>
				{m.txt}
			</ div>)}
			<button className="btn btn-info text-white mb-3" disabled={!API} onClick={buscarMsgsAntigas}>
				Buscar mais mensagens
			</button>
		</div>
		<BlocoEnviar
			loading={loading}
			msg={msg}
			onChange={e => setMsg(e.target.value)}
			onSend={() => enviarMensagem()}
			disabled={loading}
		/>
	</div>
}
